import './App.css';
import '../styles/style.scss';
import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Loader from './loader/Loader';
import { updateViewport } from '../redux/actions/layout';
import { getAppVersion, setAppVersion } from '../localstorage/app';
import appConfig from '../config/app-config';
import { signOutUser } from '../redux/actions/auth';

const Main = lazy(() => import('./Main'));

class App extends Component {
  componentDidMount = () => {
    const appVersion = getAppVersion();
    if (!appVersion || appVersion !== appConfig.appVersion) {
      setAppVersion(appConfig.appVersion);
      if (this.props.user) {
        this.props.doSignOutUSer().then(() => {
          this.props.history.push('/');
        });
      }
    }
    window.addEventListener('resize', () => {
      this.props.doUpdateViewport();
    });
  };

  render() {
    return (
      <div>
        <Suspense fallback={<Loader />}>
          <Main />
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    doSignOutUSer: () => {
      return dispatch(signOutUser());
    },
    doUpdateViewport: () => {
      dispatch(updateViewport());
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
