import { TOGGLE_SIDEMENU, UPDATE_VIEWPORT } from './types';

export function toggleSideMenu() {
  return {
    type: TOGGLE_SIDEMENU,
  };
}

export function updateViewport() {
  return {
    type: UPDATE_VIEWPORT,
  };
}
