import './fonts.css';
import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reducers from './redux/reducers';
import App from './components/App';
import register, { unregister } from './registerServiceWorker';
import Unsupported from '././components/Unsupported';
import { isLegacy } from './core/common/LegacyFixer';
import CssBaseline from '@mui/material/CssBaseline';
import config from './config/app-config';

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : (r) => r;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const nodo = () => {};

if (process.env.NODE_ENV !== 'development') {
  console.log = nodo;
  console.warn = nodo;
  console.error = nodo;
}

const app = isLegacy() ? <Unsupported /> : <App />;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// 👇️ if you use TypeScript, add non-null (!) assertion operator
// const root = createRoot(rootElement!);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <CssBaseline />
        {app}
      </div>
    </BrowserRouter>
  </Provider>
);

if ('serviceWorker' in navigator && config.useServiceWorker) {
  register();
} else {
  unregister();
}
