import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import layout from './layout';

const appReducer = combineReducers({
  app,
  auth,
  layout,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    const { app, layout } = state;
    state = { app, layout };
  }
  return appReducer(state, action);
};

export default rootReducer;
