export function setToken(jwt) {
  localStorage.setItem('JeppaAppJwtToken', jwt);
}

export function getToken() {
  return localStorage.getItem('JeppaAppJwtToken') || null;
}

export function setCompany(company) {
  localStorage.setItem('JeppaAppCompany', JSON.stringify(company));
}

export function getCompany() {
  return localStorage.getItem('JeppaAppCompany') || null;
}

export function setUserAccounts(accounts) {
  localStorage.setItem('JeppaAppUserAccounts', JSON.stringify(accounts));
}

export function getUserAccounts() {
  return localStorage.getItem('JeppaAppUserAccounts') || null;
}

export function clearToken() {
  localStorage.removeItem('JeppaApp.jwt');
  localStorage.removeItem('JeppaApp.user');
  localStorage.removeItem('JeppaJwt');
  localStorage.removeItem('JeppaAppJwtToken');
}

export function clearCompany() {
  localStorage.removeItem('JeppaAppCompany');
}

export function clearUserAccounts() {
  localStorage.removeItem('JeppaAppUserAccounts');
}

export function clear() {
  clearToken();
  clearCompany();
  clearUserAccounts();
}
