import {
  START_FETCHING,
  END_FETCHING,
  SHOW_SNACK,
  HIDE_SNACK,
  END_OFFLINE,
  START_OFFLINE,
} from '../actions/app/types';
import config from '../../config/app-config';

const initialSnack = {
  message: null,
  messageType: null,
  position: null,
  delay: null,
};

export const initialState = {
  isFetching: false,
  isOffline: !window.navigator.onLine,
  config: config,
  snack: {
    ...initialSnack,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING:
      return { ...state, isFetching: true };
    case END_FETCHING:
      return { ...state, isFetching: false };
    case START_OFFLINE:
      return { ...state, isOffline: true };
    case END_OFFLINE:
      return { ...state, isOffline: false };
    case SHOW_SNACK:
      return {
        ...state,
        snack: {
          message: action.message,
          messageType: action.messageType || null,
          position: action.position || null,
          delay: action.delay || null,
        },
      };
    case HIDE_SNACK:
      return {
        ...state,
        snack: { ...initialSnack },
      };
    default:
      return state;
  }
}
