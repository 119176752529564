import React from 'react';
import { connect } from 'react-redux';
import styles from './Loader.module.scss';

const Loader = (props) => {
  const { isFetching } = props;
  if (isFetching) {
    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <div className={styles.spinner} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return { isFetching: state.app.isFetching };
};

export default connect(mapStateToProps, null)(Loader);
