import fetchAxios from '../../middlewares/axios';
import decode from 'jwt-decode';
import { AUTH_USER } from './types';
import { ROOT_URL_ANONYMOUS, ROOT_URL_PROTECTED } from '../../../config/axios';
import { onSignIn, getTokenUser } from '../../../auth/auth';
import { clear } from '../../../localstorage';

export function signInUser({ username, password, rememberMe }) {
  return (dispatch) => {
    return new Promise(function(resolve, reject) {
      fetchAxios(dispatch, 'post', `${ROOT_URL_ANONYMOUS}/login`, {
        username,
        password,
        rememberMe,
      })
        .then((response) => {
          if (response && response.data && response.data.id_token) {
            const decoded = decode(response.data.id_token);
            onSignIn(response.data.id_token);
            dispatch({
              type: AUTH_USER,
              user: decoded.user,
            });
            resolve(response);
          } else {
            reject('NOUSER');
          }
        })
        .catch((e) => {
          reject({ error: e });
        });
    });
  };
}

export function signOutUser() {
  return (dispatch) => {
    clear();
    dispatch(reset());
    return Promise.resolve();
  };
}

export function switchUser(id) {
  return (dispatch) => {
    return new Promise(function(resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/users/requesttoken`,
        {
          user: {
            _id: id,
          },
        },
        null,
        true
      )
        .then((response) => {
          if (response && response.data && response.data.id_token) {
            clear();
            dispatch(reset());
            const decoded = decode(response.data.id_token);
            onSignIn(response.data.id_token);
            dispatch({
              type: AUTH_USER,
              user: decoded.user,
            });
            resolve();
          } else {
            reject('NOUSER');
          }
        })
        .catch((e) => {
          reject({ error: e });
        });
    });
  };
}

export function setUser() {
  const user = getTokenUser();
  if (user) {
    return {
      type: AUTH_USER,
      user: user,
    };
  }
}

function reset() {
  return {
    type: 'RESET_APP',
  };
}
