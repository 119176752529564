const appConfig = {
  appVersion: '1.0.0',
  defaultLocale: 'en',
  defaultPaginate: {
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
    count: 0,
  },
  useServiceWorker: false,
  hideSignin: true,
  isLanding: true,
  signinDate: 1658318400000,
  registrationEndDate: 1658577600000,
};

export default appConfig;
